import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import { IntlProvider, FormattedMessage } from "react-intl";

import Layout from "../components/Layout";
import SeoStructure from "../components/SeoStructure";
import ImageAds from "../components/adunits/Image-ads";
import HeaderScripts from "../components/HeadScripts";

import { isMobilePredicate } from "../utils";
import { defaultLanguage } from "../i18n";
import LocalesContext from "../context/LocalesContext";

export function Head({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const canonicalSlug = `/${pageContext.slug}`;
  const excerptWithoutTags = post.excerpt?.replace(/(<([^>]+)>)/gi, "");
  return (
    <>
      <HeaderScripts />
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags ? excerptWithoutTags : ""}
        slug={`${pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${
                !process.env.GATSBY_SITE_NAME
                  ? ""
                  : process.env.GATSBY_SITE_NAME
              }`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

function BlogPost({ data, pageContext }) {
  const post = data.posts.nodes[0];
  const [isMobile, setIsMobile] = useState(false);
  const [windowOrigin, setWindowOrigin] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const locale =
    pageContext.locale !== defaultLanguage ? `/${pageContext.locale}` : "";

  let imageAdsInserted = false;

  useEffect(() => {
    setIsMobile(isMobilePredicate());

    setWindowOrigin(window.location.origin);

    setCurrentPage(0);
  }, []);

  //Set Next Page
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //Set Prev Page
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (_.isEmpty(post)) return null;

  return (
    <>
      <article className="post post--next post--center">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post.author.node.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          <div className="post-content">
            {post.nextPageTC.map((article, index) => {
              const dataURL = `${windowOrigin}${locale}/${pageContext.slug}/${
                index + 1 === 1 ? "" : index + 1
              }`;
              return (
                <ImageAds
                  key={index}
                  index={index}
                  isMobile={isMobile}
                  dataURL={dataURL}
                  imageAdsInserted={imageAdsInserted}
                  currentPage={currentPage}
                  article={article}
                />
              );
            })}
          </div>

          <div className="code-block-next">
            <div className="paggination-buttons">
              <ul className="paggination-buttons__wrapper">
                {currentPage !== 0 && (
                  <li className="prev">
                    <button
                      onClick={() => {
                        handlePrevPage();
                        if (window.assertive) {
                          window.assertive.nextPage();
                        }
                      }}
                    >
                      ⟵
                    </button>
                  </li>
                )}
                {currentPage !== post.nextPageTC.length && (
                  <li className="next">
                    <button
                      onClick={() => {
                        handleNextPage();
                        if (window.assertive) {
                          window.assertive.nextPage();
                        }
                      }}
                    >
                      <FormattedMessage id="next" defaultMessage="Next" />
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

const BlogPosts = ({ data, pageContext }) => {
  const avaiableLangs = [pageContext.locale];

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.slug,
          avaiableLangs,
          pageNumber: pageContext.page,
        }}
      >
        <Layout containerClasses="container--center" type="post">
          <BlogPost data={data} pageContext={pageContext} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!) {
    avaiablePostTranslations: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`;
